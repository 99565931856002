<script>
	import Grid from '$lib/Components/Layout/Grid/Grid.svelte';
	import Image from '$lib/Components/Layout/Image/Image.svelte';

	let {
		title = null,
		alt = '',
		src = null,
		subtitle = null,
		description = null,
		href = null,
		subgrid = false,
		headingLevel = 'h2',
		learnMore: learnMore = null,
		loading = 'eager',
		imageClass = 'border-4 border-indow-white',
		titleSlot,
		image,
		subtitleSlot,
		descriptionSlot,
		learnMoreSlot,
	} = $props();
</script>

<Grid {subgrid} vertical center>
	{#if href}
		<a {href} class="contents">
			{#if titleSlot}{@render titleSlot()}{:else if title}
				<svelte:element
					this={headingLevel}
					class="mb-0 !text-2xl font-bold capitalize">{title}</svelte:element
				>
			{/if}
			{#if image}{@render image()}{:else if src}
				<Image
					{alt}
					{src}
					class={['!mb-0 aspect-square max-w-44 rounded-full', imageClass].join(
						' ',
					)}
					{loading}
				/>
			{/if}
			{#if subtitleSlot}{@render subtitleSlot()}{:else if subtitle}
				<p
					class="!text-indow-blue mb-0 font-medium"
					style="text-wrap: balance;"
				>
					{#if href}
						{subtitle}
					{:else}
						{subtitle}
					{/if}
				</p>
			{/if}
		</a>
	{:else}
		{#if titleSlot}{@render titleSlot()}{:else if title}
			<svelte:element
				this={headingLevel}
				class="mb-0 !text-2xl font-bold capitalize">{title}</svelte:element
			>
		{/if}
		{#if image}{@render image()}{:else if src}
			<Image {alt} {src} class={['!mb-0 max-w-44'].join(' ')} {loading} />
		{/if}

		{#if subtitleSlot}{@render subtitleSlot()}{:else if subtitle}
			<p class="!text-indow-blue mb-0 font-medium">
				{#if href}
					{subtitle}
				{:else}
					{subtitle}
				{/if}
			</p>
		{/if}
	{/if}

	{#if descriptionSlot}
		{@render descriptionSlot()}
	{:else if description}
		<p class="mb-0">{description}</p>
	{/if}
	{#if learnMoreSlot}{@render learnMoreSlot()}{:else if href && learnMore}
		<p class="mb-0 font-medium">
			<a {href}>{typeof learnMore == 'string' ? learnMore : 'Learn More'}</a>
		</p>{/if}
</Grid>
